import React from 'react';
import moment from 'moment';
import {
    Form,
    Input,
    DatePicker,
    InputNumber,
    Select,
    Radio,
    Divider,
    Button,
    Space,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select range!' }],
};

const disabledDate = current => {
    let result = false;
    if (current) {
        if (
            current < moment().subtract(90, 'years') ||
            current > moment().endOf('day')
        ) {
            result = true;
        } else {
            result = false;
        }
    }

    return result;
};

const disabledDateRange = current => {
    let result = false;
    if (current) {
        if (
            current < moment().subtract(365, 'days') ||
            current < moment().endOf('day')
        ) {
            result = true;
        } else {
            result = false;
        }
    }

    return result;
};

const EnterInfo = ({ values, change }) => {
    const handleDeps = (e, key, type) => {
        const newV = values.dependents.map((d, i) => {
            if (i === key) {
                return {
                    ...d,
                    [type]: e.target.value,
                };
            }

            return d;
        });

        change({
            ...values,
            dependents: newV,
        });
    };

    return (
        <div style={{ width: '60%', margin: '0 auto', textAlign: 'justify' }}>
            <Form
                layout="vertical"
                autoComplete="off"
                autoCapitalize="off"
                scrollToFirstError={true}
            >
                <Divider plain>Personal Information</Divider>
                <Form.Item
                    label="Full Name"
                    required={true}
                    tooltip="Your client's legal full name"
                >
                    <Input
                        value={values.name}
                        onChange={e =>
                            change({ ...values, name: e.target.value })
                        }
                    />
                </Form.Item>

                <Form.Item
                    label="E-Mail Address"
                    required={true}
                    tooltip="Your client's email address"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        },
                    ]}
                >
                    <Input
                        value={values.email}
                        onChange={e =>
                            change({ ...values, email: e.target.value })
                        }
                    />
                </Form.Item>

                <Form.Item
                    label="Street Address"
                    required={true}
                    tooltip="Your client's legal street address"
                >
                    <Input
                        value={values.address}
                        onChange={e =>
                            change({ ...values, address: e.target.value })
                        }
                    />
                </Form.Item>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Form.Item label="Date of birth" required={true}>
                        <DatePicker
                            disabledDate={disabledDate}
                            onChange={e =>
                                change({
                                    ...values,
                                    dob: moment(e).format('YYYY-MM-DD'),
                                })
                            }
                        />
                    </Form.Item>

                    <Form.Item label="Gender" required={true}>
                        <Radio.Group
                            value={values.gender}
                            onChange={e =>
                                change({ ...values, gender: e.target.value })
                            }
                        >
                            <Radio.Button value="male">Male</Radio.Button>
                            <Radio.Button value="female">Female</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Height (in)"
                        required={true}
                        tooltip="Your client's height in inches"
                    >
                        <InputNumber
                            value={values.height}
                            onChange={v => change({ ...values, height: v })}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Weight (lbs)"
                        required={true}
                        tooltip="Your client's weight in pounds"
                    >
                        <InputNumber
                            value={values.weight}
                            onChange={v => change({ ...values, weight: v })}
                        />
                    </Form.Item>
                </div>

                <div style={{ marginTop: 30, marginBottom: 50 }}>
                    <Divider plain>Depandants</Divider>

                    <Form.List name="dependents">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(
                                    ({ key, name, fieldKey, ...restField }) => {
                                        if (!values.dependents[key]) {
                                            values.dependents[key] = {
                                                first: '',
                                                last: '',
                                                relation: '',
                                            };
                                        }

                                        return (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    marginBottom: 8,
                                                }}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'first']}
                                                    fieldKey={[
                                                        fieldKey,
                                                        'first',
                                                    ]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Missing first name',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="First Name"
                                                        onChange={e =>
                                                            handleDeps(
                                                                e,
                                                                key,
                                                                'first'
                                                            )
                                                        }
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'last']}
                                                    fieldKey={[
                                                        fieldKey,
                                                        'last',
                                                    ]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Missing last name',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="Last Name"
                                                        onChange={e =>
                                                            handleDeps(
                                                                e,
                                                                key,
                                                                'last'
                                                            )
                                                        }
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'relation']}
                                                    fieldKey={[
                                                        fieldKey,
                                                        'relation',
                                                    ]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Missing relation',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="Relation"
                                                        onChange={e =>
                                                            handleDeps(
                                                                e,
                                                                key,
                                                                'relation'
                                                            )
                                                        }
                                                    />
                                                </Form.Item>

                                                <MinusCircleOutlined
                                                    onClick={() => {
                                                        const newDeps =
                                                            values.dependents.filter(
                                                                (_, i) =>
                                                                    i !== key
                                                            );

                                                        change({
                                                            ...values,
                                                            dependents: newDeps,
                                                        });

                                                        remove(name);
                                                    }}
                                                />
                                            </Space>
                                        );
                                    }
                                )}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Click to add dependant
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </div>

                <Divider plain>Policy Information</Divider>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Form.Item
                        name="policy-period"
                        label="Policy Period"
                        required={true}
                        tooltip="The date the policy begins and ends"
                        {...rangeConfig}
                    >
                        <DatePicker.RangePicker
                            disabledDate={disabledDateRange}
                            onChange={v =>
                                change({
                                    ...values,
                                    policy: {
                                        ...values.policy,
                                        period: `${moment(v[0]).format(
                                            'YYYY-MM-DD'
                                        )} - ${moment(v[1]).format(
                                            'YYYY-MM-DD'
                                        )}`,
                                    },
                                })
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name="policy-type"
                        label="Type of Policy"
                        required={true}
                        tooltip="This is the requested policy type"
                    >
                        <Select
                            value={null}
                            style={{ width: 80, margin: '0 8px' }}
                            onChange={e =>
                                change({
                                    ...values,
                                    policy: { ...values.policy, type: e },
                                })
                            }
                        >
                            <Select.Option value="life">Life</Select.Option>
                            <Select.Option value="vehicle">
                                Vehicle
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Policy Number"
                        required={true}
                        tooltip="Your client's generated policy number"
                    >
                        <InputNumber
                            disabled={true}
                            value={values.policy.number}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default EnterInfo;
