import { useState } from 'react';
import { Button, Spin } from 'antd';
import mailgun from 'mailgun-js';
import 'antd/dist/antd.css';

import logo from './sunshine-logo.png';
import SelectOption from './components/SelectOption';
import EnterInfo from './components/EnterInfo';
import './App.css';
import Confirmation from './components/Confirmation';
import ComingSoon from './components/ComingSoon';

const mail = mailgun({
    apiKey: 'e1d271026b385bd78ecd45d8e192e581-0d2e38f7-4f1cee71',
    domain: 'mailgun.platoalpha.com',
    username: 'postmaster',
});

const policyNumber = () => {
    var randVal = 10000 + Math.random() * (1000000 - 10000);
    return Math.round(randVal);
};

function App() {
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [values, setValues] = useState({
        name: '',
        email: '',
        address: '',
        dob: '',
        gender: '',
        height: '',
        weight: '',
        dependents: [],
        policy: {
            period: '',
            type: '',
            number: policyNumber(),
        },
    });

    const timeout = setTimeout(function () {
        setLoading(false);

        clearTimeout(timeout);
    }, 2000);

    const stepTwo = (type = 'new') => {
        if (type !== 'new') {
            setStep(3);

            return;
        }

        setStep(step + 1);
    };

    const sendEmail = () => {
        const deps = values.dependents.filter(k => k !== undefined);
        const data = {
            from: 'Sunshine Insurance <alpha@platoalpha.com>',
            to: values.email,
            subject: `Your insurance policy #${values.policy.number} - Sunshine Insurance`,
            text: 'Please use a different email client to fully experience this.',
            html: `
            <h3>Sunshine Insurance</h3>

            <hr />
            <p><strong>Personal</strong></p>
            <p>Name: ${values.name}</p>
            <p>Email: ${values.email}</p>
            <p>Address: ${values.address}</p>
            <p>Name: ${values.name}</p>
            <p>Date of Birth: ${values.dob}</p>
            <p>Gender: ${values.gender}</p>
            <p>Height: ${values.height}"</p>
            <p>Weight: ${values.weight}lbs</p>
            <hr />
            <p><strong>Dependents</strong></p>
            ${deps.map(
                d => `
            <p>First Name: ${d.first}</p>
            <p>Last Name: ${d.last}</p>
            <p>Relation: ${d.relation}</p>
            `
            )}
            <hr />
            <p><strong>Policy</strong></p>
            <p>Period: ${values.policy.period}</p>
            <p>Type: ${values.policy.type}</p>
            <p>Number: ${values.policy.number}</p>
            `,
            cc: 'alpha@platoalpha.com',
        };

        console.log(data);

        mail.messages().send(data, function (_, body) {
            console.log(body);
        });
    };

    return (
        <div className="App">
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        margin: '0 auto',
                        background: 'rgba(255, 255, 255, 0.4)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'all 250ms linear 2s',
                    }}
                >
                    <Spin spinning={loading} size="large" />
                </div>
            )}

            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>

            <main style={{ opacity: loading ? 0.5 : 1 }}>
                <h1>
                    Welcome to Sunshine Insurance Broker Management Platform
                </h1>
                <p>
                    Please follow the steps below to log the client's
                    information.
                </p>

                {step === 0 && <SelectOption stepTwo={stepTwo} />}
                {step === 1 && <EnterInfo values={values} change={setValues} />}
                {step === 2 && <Confirmation policy={values.policy.number} />}

                {step === 3 && (
                    <>
                        <ComingSoon />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0 auto',
                                marginTop: 30,
                                marginBottom: 50,
                                width: '60%',
                            }}
                        >
                            <Button
                                type="default"
                                onClick={() => {
                                    setStep(0);
                                }}
                            >
                                Back
                            </Button>
                        </div>
                    </>
                )}

                {step === 1 && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '0 auto',
                            marginTop: 30,
                            marginBottom: 50,
                            width: '60%',
                        }}
                    >
                        <Button
                            type="default"
                            onClick={() => {
                                setStep(step - 1);
                            }}
                        >
                            Back
                        </Button>

                        <Button
                            type="primary"
                            onClick={() => {
                                if (step === 1) {
                                    sendEmail();
                                }

                                setStep(step + 1);
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                )}
            </main>
        </div>
    );
}

export default App;
