import React from 'react';
import { Card } from 'antd';

const SelectOption = ({ stepTwo }) => {
    return (
        <div
            style={{
                width: '60%',
                margin: '50px auto',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Card
                title="New Policy"
                style={{ width: 300, cursor: 'pointer' }}
                onClick={() => stepTwo('new')}
            >
                <p>Create a new policy for a client.</p>
            </Card>
            <Card
                title="Existing Policy"
                style={{ width: 300, cursor: 'pointer' }}
                onClick={() => stepTwo('existing')}
            >
                <p>Modify a client's existing policy.</p>
            </Card>
        </div>
    );
};

export default SelectOption;
