import React from 'react';

const ComingSoon = () => {
    return (
        <div style={{ marginTop: 50 }}>
            <h2>Functionality coming soon!</h2>

            <p>
                In this module you will be able to search for an existing client
                and modify certain aspects of their insurance policy.
            </p>

            <p>
                The client and your manager will be notified of any changes made
                to the policy.
            </p>
        </div>
    );
};

export default ComingSoon;
