import React from 'react';
import { Result, Button } from 'antd';

const Confirmation = ({ policy = 0 }) => {
    return (
        <Result
            status="success"
            title="Successfully logged your client's information!"
            subTitle={`Policy number: ${policy}, your client will receive an email outlining their policy.`}
            extra={[
                <Button type="primary" key="reset">
                    Start over
                </Button>,
            ]}
        />
    );
};

export default Confirmation;
